@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --color-primary: #fecc00;
  --color-secondary: #f8d753;
  --color-black-0: #000000;
  --color-black-1: #020202;
  --color-black-2: #040404;
  --color-black-3: #060606;
  --color-black-4: #080808;
  --color-black-5: #0a0a0a;
  --color-black-6: #0c0c0c;
  --color-black-7: #0e0e0e;
  --color-black-8: #111111;
  --color-black-9: #222222;
  --color-gray-light: #dbdbdb;
  --color-gray-ultra-light: #f9f9f9;
  --color-gray-dark: #666666;
  --color-bg-dark: #333333;
  --color-light: rgba(255, 255, 255, 1);
  --color-alert: rgb(238, 44, 44);
  --color-success: rgb(74, 160, 78);
  --color-warning: rgb(252, 241, 181);
  --color-hover-black-light: rgba(0, 0, 0, .2);
  --color-hover-black-dark: rgba(0, 0, 0, .8);
  --color-hover-light: rgba(255, 255, 255, .2);
}

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, a, abbr, address, cite, code, del,
dfn, em, img, ins, kbd, q, s, samp, small, strong, sub,
sup, var, b, u, i, dl, dt, dd, ol, ul, li, fieldset, form,
label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
}

*, *:before, *:after {
  box-sizing: border-box;
}

::scrollbar { display: none; }

html {
  height: 100%;
  min-width: 320px;
  scroll-behavior: smooth; /* end body */
}

html body {
    color: var(--color-bg-dark);
    background-color: var(--color-light);
    line-height: 1;
    text-size-adjust: 100%;
    font-family: 'Dosis', sans-serif;
    height: 100%;
  }

/* end html */

.full-height {
  height: 100%;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

table th {
    font-weight:normal;
  }

label, button, input[type="submit"] {
  cursor:pointer;
}

a, a:visited, a:active, a:focus {
  text-decoration: none;
  color:var(--color-secondary);
  outline:none;
}

a:hover {
    text-decoration: none;
    color:#000;
  }

input, textarea, select {
  width: 100%;
  outline: none;
}

input.button {
  width: auto;
}

input::-webkit-input-placeholder {
    color:var(--color-gray-light);
  }

input:-moz-placeholder {
    color:var(--color-gray-light);
    opacity:1;
  }

input::-moz-placeholder {
    color:var(--color-gray-light);
    opacity:1;
  }

input:-ms-input-placeholder {
    color:var(--color-gray-light);
  }

input[type='radio'],
  input[type='checkbox'] {
    width: auto;
    vertical-align: middle;
    margin: 0 3px 0 10px;
  }

input:disabled {
    background-color: var(--color-gray-light);
    color: var(--color-gray-dark);
  }

textarea {
  height:100px;
  margin-bottom: 20px;
  width:100%
}

textarea::-webkit-input-placeholder {
    color:var(--color-gray-light);
  }

textarea:-moz-placeholder {
    color:var(--color-gray-light);
    opacity:1;
  }

textarea::-moz-placeholder {
    color:var(--color-gray-light);
    opacity:1;
  }

textarea:-ms-input-placeholder {
    color:var(--color-gray-light);
  }

select{
  cursor: pointer;
  -webkit-appearance: none;
          appearance: none;
  text-indent: 0.01px;
  text-overflow: ''
}

select::placeholder {
    color:var(--color-gray-light);
  }

select::-ms-expand {
    display: none;
  }

select:focus {
    outline: none;
  }

select:disabled {
    background-color: var(--color-gray-light);
    color: var(--color-gray-dark);
  }

select option {
    padding: 2px;
  }

span.clear {
    display:block;
    clear:both;
    height: 0;
  }

span.space{
    display:block;
    margin:10px 0;
    height:1px; clear:both;
  }

.transition, .transition:hover {
  transition:all 0.3s ease-in ;
}

::selection {
  background: var(--color-secondary);
  color: #eee;
}

.clear:after{
  content:'';
  display:block;
  clear:both;
}

.pointer {
  cursor: pointer;
}

.align-left {
  text-align:left;
}

.align-right {
  text-align:right;
}

.align-center {
  text-align:center;
}

.align-justify {
  text-align:justify;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.center{
  display:block !important;
  margin-left:auto !important;
  margin-right:auto !important;
}

.margin-top {
  margin-top:2rem;
}

.comum-color{
  color: var(--color-gray-dark);
  text-shadow: 0 0 1px rgba(0,0,0, 0.2);
}

.relative{
  position:relative;
}

.button {
  background: var(--color-primary);
  border: none;
  color: var(--color-light);
  font-family: 'Dosis', sans-serif;
  font-size: 1rem;
  display: block;
  margin: 1rem 0;
  padding: 10px 20px;
}

.button .full-width {
    width: 100%;
  }

.button.cancel {
    background: var(--color-gray-dark);
    color: var(--color-bg-dark);
  }

.button i {
    margin: 0 5px;
  }

.format-style h1, .format-style h2, .format-style h3, .format-style h4, .format-style h5, .format-style h6 {
    line-height: 70px;
    font-weight:bold;
  }

.format-style h1 {
    font-size: 45px;
  }

.format-style h2 {
    font-size: 38px;
  }

.format-style h3 {
    font-size: 32px;
  }

.format-style h4 {
    font-size: 27px;
  }

.format-style h5 {
    font-size: 24px;
  }

.format-style h6 {
    font-size: 21px;
  }

.format-style hr {
    margin:30px 0;
  }

.format-style a {
    text-decoration:underline;
    font-family: 'Dosis', sans-serif;
    font-weight: bold;
  }

.format-style p, .format-style dd {
    line-height:25px;
    margin-bottom:25px;
    font-size:14px;
  }

.format-style dt {
    font-size: 18px;
    line-height: 25px;
    font-weight:bold;
    font-family: 'Dosis', sans-serif;
  }

.format-style ul, .format-style ol {
    margin-left:20px;
  }

.format-style ul li {
      list-style:disc;
      line-height:20px;
    }

.format-style ol li {
      list-style:decimal;
      line-height:20px;
    }

.format-style fieldset {
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0 0 24px;
    padding: 11px 12px;
  }

.format-style label {
    margin-bottom: 15px;
    display: block;
  }

.format-style input[type="radio"],
  .format-style input[type="checkbox"] {
    width:auto;
    height:auto
  }

.format-style input, .format-style textarea, .format-style select {
    margin-top: 5px;
  }

.format-style button, .format-style input.button {
    padding:10px 20px;
    text-transform:uppercase;
    border: 1px solid rgba(255,255,255,0.4);
    cursor:pointer;
    outline:none;
    border: 1px solid rgba(0,0,0,0.4);
    height: auto;
  }

.format-style table td, .format-style table th {
      border:1px solid rgba(0, 0, 0, 0.1);
      padding:10px;
    }

.format-style table td {
      font-size:14px;
    }

.format-style table th {
      text-transform:uppercase;
      font-family: 'Dosis', sans-serif;
      text-align:left;
    }

.format-style pre{
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin-bottom: 24px;
    max-width: 100%;
    overflow: auto;
    padding: 10px;
    background:#fdfdfd;
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

.format-style blockquote{
    position: relative;
    padding: 30px 64px 10px;
    font-size: 34px;
    color: #D7D6D6;
    font-weight: bold;
    font-style: italic;
    font-family: 'Dosis', sans-serif;
    text-shadow: 4px 4px rgba(197, 197, 197, 0.07)
  }

.format-style blockquote:before{
      content:'\f10d';
      position:absolute;
      top:11px;
      left:0;
      font-size:60px;
      font-family:FontAwesome;
      transform:rotate(11deg);
    }

.format-style blockquote:after{
      content:'\f10e';
      position:absolute;
      bottom:11px;
      right:0;
      font-size:60px;
      font-family:FontAwesome;
      transform:rotate(11deg);
      }

.format-style blockquote * {
      color: #D7D6D6;
      line-height: 40px;
    }

.format-style strong {
    font-family: 'Dosis', sans-serif;
    font-weight: bold;
  }

/* end format-style */

.has-header {
  padding-top: 80px;
}